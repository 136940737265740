import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import './css/global.css';
import './css/tailwind.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import  Vuelidate  from 'vuelidate';

import VueSweetalert2 from 'vue-sweetalert2';
import Swal from 'sweetalert2/dist/sweetalert2.js';

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
Vue.use(Vuelidate);

Vue.use(Vuelidate);


const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  timer: 10000,
  timerProgressBar: true,
  showConfirmButton: false,
});
window.Toast = Toast;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
